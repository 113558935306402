@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500&display=swap");

* {
  box-sizing: border-box;
  font-family: "Roboto Mono", monospace;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background-color: white;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5pt;
  background-color: #d6cfcf;
}

*::-webkit-scrollbar-corner {
  background-color: white;
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
