.projects {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-top: 120px;
  padding-left: 300px;
  padding-right: 300px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 1440px) {
  .projects {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media only screen and (max-width: 640px) {
  .projects {
    padding-left: 20px;
    padding-right: 20px;
  }
}
