.App {
  position: relative;
  width: 100vw;
  height: 100vh;

  opacity: 0.85;
  max-width: 100%;
}

.animated_gradient {
  background: url("./components/home/backgroundGradient.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.welcome_message_container {
  display: flex;
  flex-direction: column;
  height: 85vh;
  justify-content: center;
  text-align: center;
}

.message {
  font-size: 35px;
  font-weight: 300;
  letter-spacing: 1pt;
  margin-bottom: 20pt;
  color: #484343;
}

.highlight {
  color: #e7e7e7;
}

.links_container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 50pt;
}

.icon {
  height: 120px;
  width: 120px;
  padding: 10pt;
  color: rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  justify-content: center;
}

.icon:hover {
  color: #e7e7e7;
  cursor: pointer;
  opacity: 0.8;
}


.heading {
  font-size: 22px;
}
@media only screen and (max-width: 640px) {
  .heading {
    font-size: 18px;
  }
}

@media only screen and (max-width: 540px) {
  .message {
    font-size: 20px;
  }
  .icon {
    height: 90px;
    width: 90px;
  }
  .links_container {
    padding-right: 0;
    justify-content: center;
  }
}
