.nav {
  display: flex;
  justify-content: space-between;
  height: 9vh;
  min-height: 1cm;
  letter-spacing: 1pt;
  font-weight: 300;
  opacity: 0.9;
}

.sticky_navbar {
  position: fixed;
  top: 0;
  width: 100%;
}

.gradient {
  background: rgb(251, 157, 63);
  background: linear-gradient(
    90deg,
    rgba(251, 157, 63, 1) 0%,
    rgba(251, 150, 73, 1) 19%,
    rgba(252, 141, 93, 1) 47%,
    rgba(252, 101, 85, 1) 76%,
    rgba(252, 100, 70, 1) 100%
  );
}

.title_container {
  display: flex;
  align-items: center;
  text-align: center;
  width: fit-content;
  font-size: 24px;
}

@media only screen and (max-width: 540px) {
  .title_container {
    font-size: 20px;
  }
}

@media only screen and (max-width: 320px) {
  .title_container {
    font-size: 18px;
  }
}



.title {
  width: fit-content;
  min-width: fit-content;
  margin-left: 50px;
  transition: 300ms;
}

.title:hover {
  color: #e7e7e7;
  cursor: pointer;
}

.navbar_elements_container {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 20px;
  margin-right: 50px;
}

.active {
  color:#e7e7e7;
}

.navbar_element {
  width: 10vw;
  min-width: fit-content;
  margin: 10px;
  transition: 300ms;
}

.navbar_element:hover {
  color: #e7e7e7;
  cursor: pointer;
}

/* Collapse navbar menu */

.burger {
  align-self: center;
  margin-right: 50px;
  display: none;
}

.burger div {
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: rgba(255, 255, 255, 0.842);
  transition: all 0.3s ease;
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
  opacity: 0;
}

.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
    width: 100%;
  }
  .navbar_elements_container {
    position: absolute;
    right: 0px;
    height: 91vh;
    top: 9vh;
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: none;
    margin-right: 0;
  }

  .burger {
    display: block;
    cursor: pointer;
  }
}

.sidebar_active {
  display: flex;
  transition: 1s;
  padding-top: 50px;
}

.sidebar_element {
  font-size: 120%;
  margin-top: 20px;
  margin-bottom: 20px;
}
